<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>このアプリとは</v-card-title>
          <v-card-text
            >お母さん食堂の全商品から3品ガチャるアプリです。</v-card-text
          >
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>そのほかのアプリ</v-card-title>
          <v-card-text>
            <ul>
              <li>
                <a href="https://daipan.yasai.dev/">台パン鳥🐓</a>:
                台パンで操作するゲーム
              </li>
            </ul>
          </v-card-text>
          <Tweet id="1358981447864905731"></Tweet>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>製作者</v-card-title>
          <v-card-text>
            <ul>
              <li>
                Twitter: <a href="https://twitter.com/YasaiDev">@YasaiDev</a>
              </li>
              <li>
                Youtube:
                <a
                  href="https://www.youtube.com/channel/UCPF7iypT3fMEjOd4h-iQUrQ"
                  >電気野菜</a
                >
              </li>
              <li>
                Amazon:
                <a
                  href="https://www.amazon.co.jp/hz/wishlist/genericItemsPage/XLGKUYNFZJH5"
                  >ほし芋</a
                >
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Tweet } from "vue-tweet-embed";
export default {
  components: {
    Tweet,
  },
};
</script>